import styled from '@emotion/styled'
import { ChangeEvent, memo, useCallback } from 'react'

import { COLOR } from '@/styles/color'

interface ToggleSwitchProps {
  label?: string
  defaultChecked?: boolean
  onToggle?: (isOn: boolean) => void
}

function ToggleSwitch({ label, defaultChecked, onToggle }: ToggleSwitchProps) {
  const handleToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isOn = e.target.checked
      onToggle?.(isOn)
    },
    [onToggle]
  )

  return (
    <Container>
      <span>{label}</span>
      <Switch>
        <Checkbox
          type="checkbox"
          defaultChecked={defaultChecked}
          onChange={handleToggle}
        />
        <Slider />
      </Switch>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  & > * + * {
    margin-left: 4px;
  }
`

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 45px;
  height: 26px;
`

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 26px;
  background-color: ${COLOR.gray.color.gray[400]};
  transition: 0.3s;

  &::before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    border-radius: 50%;
    background-color: ${COLOR.gray.color.wb[0]};
    transition: 0.3s;
  }
`

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: ${COLOR.primary.color.tmobi.blue[500]};
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px ${COLOR.primary.color.tmobi.blue[500]};
  }

  &:checked + ${Slider}::before {
    transform: translateX(19px);
  }
`

export default memo(ToggleSwitch)
