import { CONFIG } from './config'

const fontBaseUrl = CONFIG.FONT_CDN_URL || '/fonts'

export const FONT_URL = {
  REGULAR: {
    WOFF2: `${fontBaseUrl}/TMOBI_Regular.woff2`,
    WOFF: '/fonts/TMOBI_Regular.woff',
  },
  MEDIUM: {
    WOFF2: `${fontBaseUrl}/TMOBI_Medium.woff2`,
    WOFF: '/fonts/TMOBI_Medium.woff',
  },
  BOLD: {
    WOFF2: `${fontBaseUrl}/TMOBI_Bold.woff2`,
    WOFF: '/fonts/TMOBI_Bold.woff',
  },
  HEAVY: {
    WOFF2: `${fontBaseUrl}/TMOBI_Heavy.woff2`,
    WOFF: '/fonts/TMOBI_Heavy.woff',
  },
}
