import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
// 사용자 정의 포맷 관련 플러그인
import customParseFormat from 'dayjs/plugin/customParseFormat'
// 윤년 관련 플러그인
import isLeapYear from 'dayjs/plugin/isLeapYear'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import DevTools from '@/components/DevTools'
import { ConfirmProvider } from '@/components/modal'
import { CONFIG, TARGET } from '@/constants/config'
import { FONT_URL } from '@/constants/font'
import useClientInit from '@/hooks/useClientInit'
import useLink from '@/hooks/useLink'
import { sendEvent } from '@/libs/mixpanel'
import { getAccessKey, getEuk } from '@/utils/api'
import { isClient } from '@/utils/compare'

import CustomThemeProvider from '../components/provider/CustomThemeProvider'

declare global {
  interface Window {
    trackLog?: (el: HTMLAnchorElement, pageId?: string, eventName?: string, customs?: {}) => void
    openBrowserLink?: (el: HTMLAnchorElement) => void
    openBridgeLink?: (el: HTMLAnchorElement) => void
    openSchemeLink?: (el: HTMLAnchorElement) => void
  }
}

dayjs.extend(isLeapYear)
dayjs.extend(customParseFormat)
dayjs.locale('ko')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  },
})

getAccessKey()
getEuk()
export default function UserWebApp({
  Component,
  pageProps,
  appVersion,
}: AppProps & { appVersion?: string }) {
  useClientInit({ appVersion })
  const router = useRouter()

  const { openSchemeLink, openBrowserLink } = useLink()

  useEffect(() => {
    if (isClient) {
      // 파싱한 링크 정보를 로깅하기 위한 함수
      window.trackLog = (
        el: HTMLAnchorElement,
        pageId?: string,
        eventName?: string,
        customs?: {}
      ) => {
        sendEvent(pageId, eventName, customs)
      }

      // 외부 브라우저에서 링크를 열기 위한 함수
      window.openBrowserLink = (el: HTMLAnchorElement) => {
        const href = el.getAttribute('href')
        openBrowserLink(href)
      }

      // 브릿지 링크를 열기 위한 함수
      window.openBridgeLink = (el: HTMLAnchorElement) => {
        // 문자열 중 첫번째 &를 ?로 변경 후 처리한다.
        const href = el.getAttribute('href')?.replace(/tmap:\/\/(.+?)&(.+?)/g, 'tmap://$1?$2')
        openSchemeLink(href)
      }

      // 스킴 링크를 열기 위한 함수
      window.openSchemeLink = (el: HTMLAnchorElement) => {
        const href = el.getAttribute('href')
        openSchemeLink(href)
      }
    }
  }, [router, openSchemeLink, openBrowserLink])

  // resume시 repaint
  useEffect(() => {
    let off: (() => void) | null = null
    if (TmapApp.env.isInApp) {
      off = TmapApp.utils.addNativeEventListener('onResume', () => {
        const bodyEl = document.querySelector('body') as HTMLBodyElement
        bodyEl.style.transform = 'translate3d(0, 0, 0)'
        setTimeout(() => {
          bodyEl.style.transform = ''
        }, 50)
      })
    }

    return () => off?.()
  }, [])

  return (
    <>
      <Head>
        <title>티맵 고객센터</title>
        <meta
          name="description"
          content="티맵 고객센터"
        />
        <meta
          name="apple-mobile-web-app-capable"
          content="yes"
        />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="default"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0, viewport-fit=cover"
        />
        <meta
          httpEquiv="Cache-control"
          content="no-cache,no-store,must-revalidate"
        />
        <meta
          name="robots"
          content="noindex, nofollow"
        />
        <link
          rel="icon"
          href="/favicon.ico"
        />
        <link
          rel="preload"
          href={FONT_URL.REGULAR.WOFF2}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={FONT_URL.BOLD.WOFF2}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>

      <QueryClientProvider client={queryClient}>
        <CustomThemeProvider>
          <ConfirmProvider>
            <Component {...pageProps} />
            {[TARGET.LOCAL, TARGET.DEV, TARGET.DTG, TARGET.STG].includes(CONFIG.TARGET) && (
              <DevTools appVersion={appVersion} />
            )}
          </ConfirmProvider>
        </CustomThemeProvider>
      </QueryClientProvider>
    </>
  )
}

UserWebApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  return {
    ...appProps,
    appVersion: process.env.APP_VERSION,
  }
}
