import { AxiosError } from 'axios'

import { PostAuthUserResponse, postAuthUser } from '@/apis/auth/postAuthUser'
import { LOCAL_STORAGE_KEY } from '@/constants/key'

import { getAccessKey } from './api'

export const enum STATE {
  IDLE = 'idle',
  FETCHING = 'fetching',
  FETCHED = 'fetched',
}
export class ZendeskAuthManager {
  private static _state: STATE = STATE.IDLE
  private static _response: PostAuthUserResponse | null = null
  private static _requestList: { resolve: Function; reject: Function }[] = []

  /**
   * 젠데스크의 엔드 유저 여부 조회 (생성 혹은 조회 API 응답 반환)
   * @returns \{ result: 'success' \}
   */
  static async checkAuth(): Promise<PostAuthUserResponse> {
    return new Promise((resolve, reject) => {
      // 조회 성공 시
      if (this._state === STATE.FETCHED && this._response !== null) {
        resolve(this._response)
        return
      }

      if (this._state === STATE.FETCHING) {
        this._requestList.push({ resolve, reject })
        return
      }

      this._requestList.push({ resolve, reject })
      this._state = STATE.FETCHING
      this.fetch()
    })
  }

  /**
   * 실제 생성 혹은 조회 API를 호출하는 메소드
   */
  private static async fetch() {
    const ak = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_KEY) || (await getAccessKey())

    try {
      if (ak) {
        const data = await postAuthUser(ak)
        localStorage.setItem(LOCAL_STORAGE_KEY.IS_ZENDESK_AUTH_USER, 'true')
        this._response = data
        this._state = STATE.FETCHED

        for (const { resolve } of this._requestList) {
          resolve(data)
        }
      } else {
        throw new AxiosError('Access Key is not found')
      }
    } catch (error) {
      this._state = STATE.IDLE
      for (const { reject } of this._requestList) {
        reject(error)
      }
    }
  }

  static reset() {
    this._requestList = []
    this._state = STATE.IDLE
    this._response = null
  }

  static get state() {
    return this._state
  }

  static get requestList() {
    return this._requestList
  }
}
