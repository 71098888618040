export const enum TARGET {
  LOCAL = 'local',
  DEV = 'dev',
  DTG = 'dtg',
  STG = 'stg',
  RTG = 'rtg',
  PRD = 'prd',
}

export const CONFIG = {
  HOST: process.env.NEXT_PUBLIC_APP_HOST ?? '',
  APP_NAME: 'Tmap',
  TITLE: '티맵 고객센터',
  DESCRIPTION: '티맵 고객센터',
  TARGET: (process.env.NEXT_PUBLIC_TARGET as TARGET) ?? null,
  DOMAIN: process.env.NEXT_PUBLIC_DOMAIN ?? null,
  ZENDESK_FAQ_CATEGORY_ID: '20558386461723',
  ZENDESK_TICKET_FORM_ID: '360001077993',
  MIXPANEL_ID: process.env.NEXT_PUBLIC_MIXPANEL_ID ?? '',
  FONT_CDN_URL: process.env.NEXT_PUBLIC_FONT_CDN_URL ?? '',
} as const
